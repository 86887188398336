import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()
  const userData = JSON.parse(localStorage.getItem('userData'))

  const refInvoicesListTable = ref(null)

  // Table Handlers
  let tableColumns = [
    { label: 'Factuurnummer', key: 'id', sortable: true },
    { label: 'Datum', key: 'createTimestamp', sortable: true },
    { label: 'verloningsperiode', key: 'paycheckPeriod', sortable: true },
    { label: 'Opdrachtgever', key: 'companyID', sortable: true },
    { label: 'Beschrijving', key: 'description', sortable: true },
    { label: 'Bedrag', key: 'totalAmount', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: '', key: 'actions', sortable: false },
  ]

  if (userData.type === 2) {
    tableColumns = [
      { label: '', key: 'select' },
      { label: 'Factuurnummer', key: 'id', sortable: true },
      { label: 'Datum', key: 'createTimestamp', sortable: true },
      { label: 'Freelancer', key: 'freelancerName', sortable: true },
      { label: 'Beschrijving', key: 'description', sortable: true },
      { label: 'Bedrag', key: 'totalAmount', sortable: true },
      { label: 'Status', key: 'status', sortable: true },
      { label: '', key: 'actions', sortable: false },
    ]
  }

  const perPage = ref(25)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const organisationFilter = ref(null)
  const statusFilter = ref(null)
  const showOverlay = ref(false)
  const searchQuery = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refInvoicesListTable.value ? refInvoicesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoicesListTable.value.refresh()
  }

  watch([currentPage, perPage, statusFilter, searchQuery], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('app-invoices/fetchInvoices', {
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
        status: statusFilter.value,
        companyID: organisationFilter.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalInvoices.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ophalen facturen',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
          },
        })
      })
  }

  const resolveTypeVariant = status => {
    if (status === 1) return { status: 'Nieuw', variant: 'warning' }
    if (status === 10) return { status: 'In behandeling door Payrite', variant: 'warning' }
    if (status === 11) return { status: 'Controle Freelancer', variant: 'warning' }
    if (status === 12) return { status: 'Controle Opdrachtgever', variant: 'warning' }
    if (status === 20) return { status: 'Open', variant: 'warning' }
    if (status === 50) return { status: 'Afgekeurd', variant: 'danger' }
    if (status === 51) return { status: 'Afgekeurd', variant: 'danger' }
    if (status === 52) return { status: 'Afgekeurd', variant: 'danger' }
    if (status === 90) return { status: 'Betaald', variant: 'success' }
    return { tooltip: 'Verstuurd', icon: 'CreditCardIcon', variant: 'warning' }
  }

  const statusOptions = [
    { label: 'Niet betaald', value: 1 },
    { label: 'Betaald', value: 2 },
  ]

  return {
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    sortBy,
    refInvoicesListTable,
    statusFilter,
    organisationFilter,
    resolveTypeVariant,
    statusOptions,
    refetchData,
    showOverlay,
    isSortDirDesc,
    searchQuery,
    fetchInvoices,
    userData,
  }
}
